document.addEventListener("turbo:frame-missing", function(event) {
  const { detail: { response, visit } } = event

  // If the frame's request resulted in a redirect, visit the redirect target
  // NOTE: This only works because most controllers redefine the layout they
  // want to use, if it wasn't so Turbo's layout would be used and this would
  // result in a completely broken layout
  if (response.redirected) {
    event.preventDefault()
    // This replaces the body with the one from the response, if we were to
    // navigate here then we would have to render the same page twice, once
    // with the response we just got and are responding to and another when
    // we navigate to the redirect target
    visit(response)
  }
})

// show progress bar for turbo-frames load, should be removed when supported by Turbo
// add data-turbo-progress-bar="true" to the turbo frame which needs to display the progress bar
// https://github.com/hotwired/turbo/issues/540

const adapter = Turbo.navigator.delegate.adapter
const session = Turbo.session

let progressBarTimeout = null
document.addEventListener("turbo:before-fetch-request", (event) => {
  const target = event.target
  if (!(target instanceof HTMLElement)) {
    return
  }

  if ("true" === target.getAttribute("data-turbo-progress-bar")) {
    adapter.progressBar.setValue(0)
    progressBarTimeout = window.setTimeout(adapter.showProgressBar, session.progressBarDelay)
  }
})

document.addEventListener("turbo:before-fetch-response", () => {
  if (progressBarTimeout) {
    window.clearTimeout(progressBarTimeout)
    progressBarTimeout = null
  }
})

// Custom turbo confirmation modal
Turbo.setConfirmMethod((message, element) => {
  let locale = {}

  if (document.body.dataset.confirmationModalLocale) {
    try {
      locale = JSON.parse(document.body.dataset.confirmationModalLocale)
    }
    catch {}
  }

  const titleLabel = locale.title || "Are you sure?"
  const cancelLabel = locale.cancel || "Cancel"
  const confirmLabel = locale.confirm || "Confirm"

  const modalContainer = document.createElement("div")

  modalContainer.innerHTML = `
    <div class="confirmation">
      <div id="turbo-confirm" class="modal fade">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">

            <div class="modal-header pt-[25px]">
              <h4 class="modal-title">${titleLabel}</h4>
            </div>

            <div class="modal-body"></div>

            <div class="custom-modal-footer">

              <div class="row display-desktop">
                <div class="col-md-12">
                  <div class="pull-right">
                    <div class="button-group inline-block m-r-sm hidden-xs hidden-sm">
                      <a href="" class="btn btn-link">${cancelLabel}</a>
                      <button value="confirm" class="btn btn-primary">${confirmLabel}</button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row display-mobile mobile_view">
                <div class="col-md-12">
                  <div class="flex-mobile justify-between hidden-md hidden-lg">
                    <a href="" class="btn btn-link">${cancelLabel}</a>
                    <button value="confirm" class="btn btn-primary">${confirmLabel}</button>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>
  `

  document.body.appendChild(modalContainer)
  const modal = modalContainer.querySelector("#turbo-confirm")

  modal.querySelector(".modal-body").innerHTML = ""
  modal.querySelector(".modal-body").appendChild(document.createTextNode(message))

  $(modal).modal("show")

  return new Promise((resolve, reject) => {
    modal.querySelector(".custom-modal-footer").addEventListener("click", (event) => {
      event.preventDefault()
      if (event.target.classList.contains("btn")) {
        $(modal).modal("hide")
        $(modal).on("hidden.bs.modal", (e) => modalContainer.remove())

        resolve(event.target.value === "confirm")

        if (event.target.value !== "confirm") {
          element.dispatchEvent(new CustomEvent("turbo-confirm:cancel"))
        }
      }
    }, {once: true})
  })
})
